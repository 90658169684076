import React, { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import "./UpdateTextFile.css";

const UpdateTextFile = ({
  onClose,
  subdomain,
  fileName,
  fileContent,
  onUpdateSuccess
}) => {
  const [updatedSubdomain, setupdatedSubdomain] = useState(subdomain);
  const [updatedFileName, setUpdatedFileName] = useState(fileName);
  const [updatedFileContent, setUpdatedFileContent] = useState(fileContent);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  
  const handleUpdateFile = async () => {
    console.log( {
      updatedSubdomain,
      updatedFileName,
      content: updatedFileContent,
      fileName : encodeURIComponent(fileName)
    });
    try {
      if (!updatedFileName || !updatedFileContent) {
        setAlertMessage("Please enter both file name and content");
        setAlertSeverity("error");
        return;
      }

      await axios.put(
        `https://freeallgame.com/api/update-file`,
        {
          updatedSubdomain,
          updatedFileName,
          content: updatedFileContent,
          fileName : encodeURIComponent(fileName)
        }
      );

      onUpdateSuccess({
        fileName: updatedFileName,
        fileContent: updatedFileContent
      });
     
      setAlertMessage("File updated successfully");
      setAlertSeverity("success");
    } catch (error) {
      console.error("Error updating file:", error);
      setAlertMessage("Error updating file");
      setAlertSeverity("error");
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <button className="close-button" onClick={onClose}>
            <span className="material-icons close-icon">close</span>
          </button>
          <div className="update-form">
            <p>{updatedSubdomain}</p>
            <input
              type="text"
              placeholder="File Name"
              value={updatedFileName}
              onChange={(e) => setUpdatedFileName(e.target.value)}
              required
            />
            <textarea
              placeholder="File Content"
              value={updatedFileContent} 
              onChange={(e) => setUpdatedFileContent(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={handleUpdateFile}
              className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              Update
            </button>
          </div>
          {alertMessage && (
            <Alert
              variant="filled"
              severity={alertSeverity}
              onClose={handleCloseAlert}
            >
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateTextFile;