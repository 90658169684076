import React, { useState, useRef } from "react";
import Slider from "react-slick";

const Sliders = ({ phoneScreenshots }) => {
  const [isPaused, setIsPaused] = useState(false);
  const sliderRef = useRef(null);

  if (!phoneScreenshots || !Array.isArray(phoneScreenshots)) {
    return null;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: !isPaused,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  const handleHover = () => {
    setIsPaused(!isPaused);
    if (!isPaused) {
      sliderRef.current.slickPause();
    } else {
      sliderRef.current.slickPlay();
    }
  };

  return (
    <div className="w-full bg-[#FFF] py-30">
      <div className="md:max-w-[1480px]  m-auto max-w-[600px] px-4 md:px-0">
        <div className="py-4">
          <h1 className="py-3 text-[#20B486] text-3xl font-bold">
            Screenshots
          </h1>
        </div>

        <Slider {...settings} ref={sliderRef}>
          {phoneScreenshots.map((screenshot, index) => (
            <div className="slide" key={index}>
              <div
                className="z-10 drop-shadow-xl overflow-hidden rounded-2xl mr-2 my-2"
                style={{ maxWidth: "100%", height: "auto" }}
              >
                <div
                  className="rounded-2xl overflow-hidden"
                  style={{ height: "100%", width: "100%" }}
                >
                  <img
                    src={screenshot}
                    alt={`Screenshot Loading..`}
                    className="rounded-2xl"
                    style={{
                      maxWidth: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center"
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Sliders;
