import React, { useEffect, useState } from "react";
import sidebarImage from "../../assets/images/bg-sidebar-desktop.svg";
import StepsCardComponent from "../StepsCardComponent/StepsCardComponent";
import { Link, useLocation } from "react-router-dom";
import "./StepperSIdebar.css";
const StepperSIdebar = () => {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();

  const numSteps = [
    { step: 1, stepTitle: "ADD YOUR APP", route: "" },
    { step: 2, stepTitle: "GENERATE SUBDOMAIN", route: "addsubdomain" },
    { step: 3, stepTitle: "GENERATE APP-ADS.TXT", route: "add-app-ads-txt" },
    { step: 4, stepTitle: "PUBLISH", route: "finish" }
  ];

  useEffect(() => {
    const currentRoute = location.pathname.replace("/", "");
    const stepIndex = numSteps.findIndex((step) => step.route === currentRoute);
    if (stepIndex !== -1) {
      setActiveStep(stepIndex + 1);
    }
  }, [location]);

  return (
    <div className="relative w-[35%] h-full bg-slate-200 overflow-hidden rounded-lg side-bar">
      <img
        src={sidebarImage}
        alt="sidebar"
        className="w-full h-full object-cover rounded-lg"
      />
      <div className="absolute h-1/2 top-0 left-0 right-0 flex flex-col justify-between items-start text-white p-6">
        {numSteps.map((step, index) => (
          <Link
            to={index < activeStep ? `/${step.route}` : "#"}
            key={index + 1}
          >
            <StepsCardComponent
              step={step.step}
              stepTitle={step.stepTitle}
              style={`mt-4`}
              route={step.route}
              active={activeStep === step.step}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default StepperSIdebar;