import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useStep } from "../../StepContext";
import Alert from "@mui/material/Alert";
import "./Final.css";

const Final = () => {

  const { currentStep } = useStep();
  const location = useLocation();
  const subdomain = location.state?.subdomain;
  const fullSubdomain = `${subdomain}.freeallgame.com`;
  const [isCopied, setIsCopied] = useState(false);

  
  const handleCopy = () => {
    const textField = document.createElement("textarea");
    textField.innerText = fullSubdomain;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (currentStep !== 4) {
    return <Navigate to="/" />;
  }

  return (
    <div className="w-2/3 h-full mx-auto pt-[5%]">
      <h1 className="text-3xl font-bold mb-4 ml-[16.5%]">Congratulations</h1>
      <div className="flex justify-around mt-[10%] w-[88%]">
        <h2 className="text-2xl font-bold mb-4 ml-[16.5%]">
          Your Site is Live 🚀
        </h2>
      </div>
      <div className="flex justify-around mt-[10%] w-[88%]">
        <div className="copy-container">
          <div className="copy-text">
            <input
              type="text"
              className="text"
              value={fullSubdomain}
              readOnly
            />
            <button onClick={handleCopy}>
              <span className="material-icons copy-icon">content_copy</span>
            </button>
          </div>
          {isCopied && (
            <div className="tooltip">
              <span className="tooltiptext">Copied!</span>
            </div>
          )}
        </div>
      </div>
      {currentStep !== 4 && (
        <Alert severity="warning">
          Please complete the previous steps first.
        </Alert>
      )}
    </div>
  );
};

export default Final;
