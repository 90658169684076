import React, { useState, useEffect } from "react";
import axios from "axios";
import CreateTextFile from "../CreateTextFile/CreateTextFile";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStep } from "../../StepContext";
import Alert from "@mui/material/Alert";


const AddAppAdsTxt = ({ onBack, fileContent }) => {
  const { currentStep, goToNextStep } = useStep();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const location = useLocation();
  const subdomain = location.state?.subdomain;
  const navigate = useNavigate();

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        `https://freeallgame.com/api/files?subdomain=${subdomain}`
      );
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleCreateFileSuccess = (newFile) => {
    setShowCreateModal(true);
    fetchFiles();
  };

  const handleNextStep = async () => {
    try {   
      const myRes = await axios.post("https://freeallgame.com/api/getfile", {
        fileName: selectedFile
      });

      if (!myRes.data) {
        // Store the selected file for the subdomain in the database
        await axios.post("https://freeallgame.com/api/associate-file", {
          subdomain,
          fileName: selectedFile
        });
      } else {
        const newRes = await axios.post("https://freeallgame.com/api/getfile", {
          fileName: selectedFile
        });
        console.log("New Response:", newRes);
        let newSudomain = newRes.data.subdomain;
        const res = await axios.post("https://freeallgame.com/api/get-file", {
          subdomain: newSudomain,
          fileName: selectedFile
        });
        let newContent = res.data.content;
        let originalFileName = selectedFile.split(".")[0]; 
        console.log("Original File Name:", originalFileName);
        await axios.post("https://freeallgame.com/api/create-file", {
          subdomain,
          fileName: originalFileName,
          content: newContent
        });

        await axios.post("https://freeallgame.com/api/associate-file", {
          subdomain,
          fileName: originalFileName
        });
        
      }
      await axios.post("https://freeallgame.com/api/create-dns-record", {
        subdomain: subdomain
      });
      

      goToNextStep();
      navigate("/final", {
        state: {
          subdomain,
          selectedFile
        }
      });
    } catch (error) {
      console.error("Error associating file with subdomain:", error);
    }
  };

  const updateDropdownAfterDelete = (deletedFileName) => {
    const updatedFiles = files.filter(
      (file) => file.fileName !== deletedFileName
    );
    setFiles(updatedFiles);

    if (selectedFile === deletedFileName) {
      setSelectedFile("");
    }
  };

  return (
    <div className="w-2/3 h-full mx-auto pt-[5%]">
      {currentStep === 3 && (
        <>
          <h1 className="text-3xl font-bold mb-4 ml-[16.5%]">
            Add APP-ADS.TXT Records
          </h1>

          {showCreateModal && (
            <CreateTextFile
              onClose={() => setShowCreateModal(false)}
              onCreateSuccess={handleCreateFileSuccess}
              subdomain={subdomain}
              updateDropdownAfterDelete={updateDropdownAfterDelete}
            />
          )}

          <div className="flex justify-around mt-[10%] w-[88%]">
            <div className="bg-gray-700 text-white rounded px-6 py-3">
              <button type="button" onClick={() => setShowCreateModal(true)}>
                Manage
              </button>
            </div>
          </div>

          <div className="flex justify-around mt-[10%] w-[88%]">
            <select
              id="fileDropdown"
              value={selectedFile}
              onChange={(e) => setSelectedFile(e.target.value)}
            >
              <option value="">Select file...</option>
              {files.map((file, index) => (
                <option key={index} value={file.fileName}>
                  {file.fileName} - {file.subdomain}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-around mt-[10%] w-[88%]">
            {/* <div className="text-gray-500 font-bold px-6 py-3 rounded ">
              <Link to={"/addsubdomain"}>
                <button type="button" onClick={onBack}>
                  Go Back
                </button>
              </Link>
            </div> */}
            <div className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
              <button type="button" onClick={handleNextStep}>
                Next Step
              </button>
            </div>
          </div>
        </>
      )}  
      {currentStep !== 3 && (
        <Alert severity="warning">
          Please complete the current step first.
        </Alert>
      )}
    </div>
  );
};

export default AddAppAdsTxt;