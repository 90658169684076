import React, { useEffect, useState } from "react";

const DisplayTextFile = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUrl = window.location.href;

        const parsedUrl = new URL(currentUrl);

        const hostname = parsedUrl.hostname;

        const subdomain = hostname.split(".")[0];

        console.log(subdomain);
        const response = await fetch(
          `https://${subdomain}.freeallgame.com/app-ads.txt`
        );
        const res = await fetch(
          `https://${subdomain}.freeallgame.com/check`
        );
        console.log(response, "response");
        console.log(res, "res");
        const result = await response.text();
        console.log("Result:", result);
        setData(result.fileContent);
        console.log("Data:", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1 style={{ color: "#000", whiteSpace: "pre-wrap" }}>{data}</h1>
    </div>
  );
};

export default DisplayTextFile;