import React from "react";
import "./StepsCardComponent.css";
const StepsCardComponent = ({ step, style, stepTitle, active }) => {
  return (
    <div className={`flex items-center ${style} display-card`}>
      <div
        className={`${
          active ? "bg-blue-200 text-black" : "bg-transparent"
        } rounded-full flex items-center justify-center mr-2 border border-gray-100`}
        style={{
          width: "2.5rem",
          height: "2.5rem",
          minWidth: "2.5rem",
          minHeight: "2.5rem"
        }}
      >
        <span className="text-sm md:text-xl font-bold step-no">{step}</span>
      </div>
      <h2 className="text-xs md:text-base font-bold opacity-90 step-title">
        {stepTitle}
      </h2>
    </div>
  );
};

export default StepsCardComponent;