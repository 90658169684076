import React, { createContext, useContext, useState } from "react";

const StepContext = createContext();

export const StepProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const goToNextStep = () => {
    setCompletedSteps((prevSteps) => [...prevSteps, currentStep]);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  return (
    <StepContext.Provider value={{ currentStep, goToNextStep, completedSteps }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => useContext(StepContext);
